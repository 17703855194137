import Vue from 'vue';

import svg_icons from './common/svg_icons.vue';

// SASS/CSS
import '../../css/sass/public.scss';

// images
import '../../images/icons-public.svg';

$(function () {
    $('.js-header-nav_item-more').on('click', function (e) {
        e.preventDefault();
        $('.js-header').addClass('header-mobile_open');
    });

    $('.js-header-nav_item-less').on('click', function (e) {
        e.preventDefault();
        $('.js-header').removeClass('header-mobile_open');
    });

    // on window resize, we want to "close" the mobile menu
    $(window).on('resize', function () {
        $('.js-header').removeClass('header-mobile_open');
    });

    $('.js-faq-item').on('click', 'a.js-faq-question', function(e) {
        e.preventDefault();
        $(this).siblings('.js-faq-answer').slideToggle();
    });

    // @todo remove the following (to end of file)?
    // for automatic resizing of youtube & vimeo videos
    // pull & modified from: https://github.com/chriscoyier/Fluid-Width-Video
    // var $allVideos = $("iframe[src*='//www.youtube.com'], iframe[src*='//player.vimeo.com']"),
    //     $fluidEl = $(".js-blog_content-video_wrap");
    //
    // $allVideos.each(function() {
    //     $(this)
    //     // jQuery .data does not work on object/embed elements
    //         .attr('data-aspectRatio', this.height / this.width)
    //         .removeAttr('height')
    //         .removeAttr('width');
    // });
    //
    // $(window).resize(function() {
    //     var newWidth = $fluidEl.width();
    //
    //     $allVideos.each(function() {
    //
    //         var $el = $(this);
    //         $el
    //             .width(newWidth)
    //             .height(newWidth * $el.attr('data-aspectRatio'));
    //
    //     });
    // }).resize();
});

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',

    components: {
        'svg-icons': svg_icons,
    },
});
